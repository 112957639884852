<template>

  <transition name="modalvideo">
    <div class="modal-mask fixed top-0 left-0 w-full h-auto outline-none fade">

        <div class="modal-container w-full my-0 mx-auto rounded shadow-lg z-50 h-full bg-black ">

           <i class="fas fa-times-circle cursor-pointer fixed right-0 items-center mt-10 lg:mt-0 rounded-2  mr-10 p-2 text-white text-5xl z-50"
           @click="$emit('close')" style="right:-12px;"></i>
            <div class="h-screen overflow-y-auto ">
            <slot name="body">
              <iframe class='sproutvideo-player mt-10' v-bind:src="videoURL()" frameborder='0' scrolling="no" style="width: 100%;height: 80vh;position: relative;" allowfullscreen></iframe>
            </slot>
          </div>
        </div>
  
    </div>
  </transition>

</template>

<script>

export default {
  name: "modalvideo",
  props: {
    url: String,
  },
  methods: {
    videoURL() {
      return (
        this.url + "?autoPlay=true&amp;playerTheme=dark&amp;playerColor="
      );
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.video-icon {
  font-size: 31px;
  margin-top: -11px;
  cursor: pointer;
}

.video-icon:hover i {
  opacity: 0.9;
}


</style>
